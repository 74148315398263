<template>
    <div>
        <v-form lazy-validation ref="localSalesInvoiceItemsForm">
            <v-data-table
                :disable-pagination="true"
                :items-per-page="-1"
                :hide-default-footer="true"
                :headers="headers"
                :height="LocalSalesInvoiceItems.length < 2 ? '210px' : null"
                :items="LocalSalesInvoiceItems"
                :show-select="showSelect"
                calculate-widths
                class="mt-3 appic-table-light specification-table"
                dense
                disable-sort
                hide-default-header
                item-key="index"
                v-model="allSelectedItems"
            >
                <template v-slot:header="{ props: { headers, everyItem, someItems }, on: headerOn }">
                    <tr>
                        <th class="grey lighten-3 pa-1 text-center width-1-pct">{{ headers[0].text }}</th>
                        <th class="grey lighten-3 pa-1 text-left">{{ headers[1].text }}</th>
                        <th class="grey lighten-3 pa-1 text-left width-35-pct">
                            <v-row no-gutters>
                                <v-col cols="6">{{ $t('message.product') }}</v-col>
                                <v-col cols="6" class="pl-1">{{ $t('message.spec') }}</v-col>
                            </v-row>
                        </th>
                        <th class="grey lighten-3 pa-1 text-left width-30-pct">
                            <v-row no-gutters>
                                <v-col cols="6">{{ $t('message.size') }}</v-col>
                                <v-col cols="6" class="pl-1">{{ $t('message.quantity') }}</v-col>
                            </v-row>
                        </th>
                        <th class="grey lighten-3 pa-1 text-center width-1-pct">
                            <template v-if="LocalSalesInvoice__is_posted != 1">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-simple-checkbox
                                            :value="everyItem"
                                            :indeterminate="someItems && !everyItem"
                                            v-on="on"
                                            v-bind="attrs"
                                            @input="headerOn['toggle-select-all']"
                                        />
                                    </template>
                                    <span>{{ $t('message.selectDeselectPriceEdit') }}</span>
                                </v-tooltip>
                            </template>
                        </th>
                        <th class="grey lighten-3 pa-1 text-left width-20-pct">
                            {{ headers[5].text }}
                            <v-btn v-if="LocalSalesInvoice__is_posted != 1" small class="ml-6" @click.stop="openEditPricesDialog">{{ $t('message.editPrices') }}</v-btn>
                        </th>
                        <th class="grey lighten-3 pa-1 text-left width-15-pct">{{ headers[6].text }}</th>
                        <th class="grey lighten-3 pa-1 text-left width-5-pct">{{ headers[7].text }}</th>
                        <th class="grey lighten-3 pa-1 text-left width-1-pct">{{ headers[8].text }}</th>
                    </tr>
                </template>
                <template v-slot:item.lineNumber="{ item }">
                    <div class="text-center mt-2 font-16px">{{ item.lineNumber }}</div>
                </template>
                <template v-slot:item.Stock.bundleno="{ item }">
                    <div class="font-weight-bold mt-2 text-no-wrap font-16px">{{ item.Stock.bundleno }}</div>
                    <v-btn small @click="openEditItemDialog( item.index )" v-if="item.type == 'default' && LocalSalesInvoice__is_posted != 1">{{ $t('message.editItemInfo') }}</v-btn>
                </template>
                <template v-slot:item.Stock.productgroup_id="{ item }">
                    <v-row no-gutters v-if="item.type == 'default'">
                        <v-col cols="6">
                            <v-row no-gutters class="pt-1">
                                <v-col cols="5" class="font-16px">{{ $t('message.product') + ":" }}</v-col>
                                <v-col cols="7" class="font-16px input-box">{{ item.Productgroup.title }}</v-col>
                            </v-row>
                            <v-row no-gutters class="pt-1">
                                <v-col cols="5" class="font-16px">{{ $t('message.species') + ":" }}</v-col>
                                <v-col cols="7" class="font-16px input-box">{{ item.Species.title }}</v-col>
                            </v-row>
                            <v-row no-gutters class="pt-1 mb-1">
                                <v-col cols="5" class="font-16px">{{ $t('message.certification') + ":" }}</v-col>
                                <v-col cols="7" class="font-16px input-box">{{ item.Certification.title }}</v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="6" class="pl-1">
                            <v-row no-gutters class="pt-1">
                                <v-col cols="5" class="font-16px">{{ $t('message.grade') + ":" }}</v-col>
                                <v-col cols="7" class="font-16px input-box">{{ item.Grade.title }}</v-col>
                            </v-row>
                            <v-row no-gutters class="pt-1">
                                <v-col cols="5" class="font-16px">{{ $t('message.spec') + ":" }}</v-col>
                                <v-col cols="7" class="font-16px input-box">{{ item.Spec.title }}</v-col>
                            </v-row>
                            <v-row no-gutters class="pt-1 mb-1">
                                <v-col cols="5" class="font-16px">{{ $t('message.mc') + ":" }}</v-col>
                                <v-col cols="7" class="font-16px input-box">{{ item.Mc.title }}</v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row no-gutters v-if="item.type == 'othercharge'">
                        <v-col cols="12">
                            <v-text-field
                                :rules="[...validationRules.plainTextWithNumberAndSymbols]"
                                :value="item.Stock.size"
                                autocomplete="off"
                                class="custom force-text-left pa-1 bg-lightgrey mb-2"
                                clearable
                                dense
                                hide-details="auto"
                                @change="updateLocalSalesInvoiceItemRowHandle( item.index, 'Stock.size',$event)"
                            />
                        </v-col>
                    </v-row>
                    <template v-if="updateMode == false && item.Errors && item.Errors.length > 0">
                        <v-alert class="mt-1 font-16px mb-1" dense text type="warning" icon="warning">
                            <div class="d-flex flex-column">
                                <template v-for="error in item.Errors">
                                    <span class="black--text">{{ error }} </span>
                                </template>
                                <span class="black--text">{{ $t('message.errors.soCannotConvert') }}</span>
                            </div>
                        </v-alert>
                    </template>
                </template>
                <template v-slot:item.Stock.size_id="{ item }">
                    <v-row no-gutters v-if="item.type == 'default'">
                        <v-col cols="6">
                            <v-row no-gutters class="pt-1">
                                <v-col cols="5" class="font-16px">{{ $t('message.thickness') + ":" }}</v-col>
                                <v-col cols="7" class="font-16px input-box">{{ getThickness(item.Stock.thickness_id) }}</v-col>
                            </v-row>
                            <v-row no-gutters class="pt-1">
                                <v-col cols="5" class="font-16px">{{ $t('message.width') + ":" }}</v-col>
                                <v-col cols="7" class="font-16px input-box">{{ item.Stock.width }}</v-col>
                            </v-row>
                            <v-row no-gutters class="pt-1 mb-1">
                                <v-col cols="5" class="font-16px">{{ $t('message.length') + ":" }}</v-col>
                                <v-col cols="7" class="font-16px input-box">{{ item.Stock.length }}</v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="6">
                            <v-row no-gutters class="pt-1">
                                <v-col cols="5" class="font-16px pl-1">{{ $t('message.pcs') + ":" }}</v-col>
                                <v-col cols="7" class="font-16px input-box text-right">{{ item.Stock.piece }}</v-col>
                            </v-row>
                            <v-row no-gutters class="pt-1">
                                <v-col cols="5" class="font-16px pl-1">{{ $t('message.qty') + ":" }}</v-col>
                                <v-col cols="7" class="font-16px input-box text-right">{{ item.Stock.volume }}</v-col>
                            </v-row>
                            <v-row no-gutters class="pt-1 mb-1">
                                <v-col cols="5" class="font-16px pl-1">{{ $t('message.unit') + ":" }}</v-col>
                                <v-col cols="7" class="font-16px input-box text-right">{{ item.Soldmeasurement.title }}</v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </template>
                <template v-slot:item.data-table-select="{ item, isSelected, select }">
                    <template v-if="item.type == 'default'">
                        <v-simple-checkbox :value="isSelected" @input="select($event)"/>
                    </template>
                    <template v-else>
                        <div class="ml-2 mt-1 font-16px">{{ $t('message.na') }}</div>
                    </template>
                </template>
                <template v-slot:item.Stock.costprice="{ item }">
                    <template v-if="item.type == 'default'">
                        <v-row no-gutters class="pt-1">
                            <v-col cols="5" class="font-16px">{{ $t('message.buyingPrice') + ":" }}</v-col>
                            <v-col cols="5" class="font-16px input-box text-right">{{ formatThisNumber(item.Stock.costprice, currencyFormatPrecision) }}</v-col>
                            <v-col cols="2" class="font-16px input-box">{{ getCurrencyCode(item.Stockorder.currency_id)  }}</v-col>
                        </v-row>
                        <v-row no-gutters class="pt-1">
                            <v-col cols="5" class="font-16px">{{ $t('message.importDuty') + ":" }}</v-col>
                            <v-col cols="5" class="font-16px input-box text-right">{{ formatThisNumber(item.Stock.importduty, currencyFormatPrecision) }}</v-col>
                            <v-col cols="2" class="font-16px input-box">{{ getCurrencyCode(item.Stockorder.currency_id)  }}</v-col>
                        </v-row>
                        <v-row no-gutters class="pt-1">
                            <v-col cols="5" class="font-16px">{{ $t('message.storageCost') + ":" }}</v-col>
                            <v-col cols="5" class="font-16px input-box text-right">{{ formatThisNumber(item.Stock.warehousecost, currencyFormatPrecision) }}</v-col>
                            <v-col cols="2" class="font-16px input-box">{{ getCurrencyCode(item.Stockorder.currency_id)  }}</v-col>
                        </v-row>
                        <v-row no-gutters class="pt-1 mb-1">
                            <v-col cols="5" class="font-16px">{{ $t('message.freightCost') + ":" }}</v-col>
                            <v-col cols="5" class="font-16px input-box text-right">{{ formatThisNumber(item.Stock.forwardingcharge, currencyFormatPrecision) }}</v-col>
                            <v-col cols="2" class="font-16px input-box">{{ getCurrencyCode(item.Stockorder.currency_id)  }}</v-col>
                        </v-row>
                    </template>
                </template>
                <template v-slot:item.Stock.sellingprice="{ item }">
                    <v-row no-gutters class="pt-1" v-if="item.type == 'default'">
                        <v-col cols="8" class="font-16px input-box text-right">{{ formatThisNumber(item.Stock.sellingprice, currencyFormatPrecision) }}</v-col>
                        <v-col cols="3" class="font-16px input-box">{{ getCurrencyCode(LocalSalesInvoice__currency_id) }}</v-col>
                    </v-row>
                    <div class="d-flex flex-row align-center" v-if="item.type == 'othercharge'">
                        <v-text-field
                            :rules="[...validationRules.amount]"
                            :value="item.Stock.sellingprice"
                            autocomplete="off"
                            class="custom pa-1 bg-lightgrey mb-2"
                            clearable
                            dense
                            hide-details="auto"
                            @change="updateLocalSalesInvoiceItemRowHandle( item.index, 'Stock.sellingprice',$event)"
                        />
                        <span class="ml-1 mt-2 font-16px">{{ getCurrencyCode(LocalSalesInvoice__currency_id) }}</span>
                    </div>

                </template>
                <template v-slot:item.Stock.amount="{ item }">
                    <div class="mt-2 text-no-wrap text-right align-center font-16px" style="height: 30px">{{ formatThisNumber(item.Stock.amount, findCurrencyPrecision(LocalSalesInvoice__currency_id)) + ' ' + getCurrencyCode(LocalSalesInvoice__currency_id) }}</div>
                </template>
                <template v-slot:item.Stock.id="{ item }">
                    <div class="ml-2" v-if="item.type == 'othercharge'">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn icon @click="deleteItem(item.index, item.Stock.id)" v-on="on">
                                    <v-icon color="grey">delete</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('message.deleteThisItem') }}</span>
                        </v-tooltip>
                    </div>
                    <div v-else class="mt-2 text-center font-weight-bold font-16px">{{ $t('message.na') }}</div>
                </template>
                <template v-slot:footer>
                    <div class="pt-0 d-flex justify-space-between">
                        <div class="col-4 col-lg-4 col-md-3 pt-5 px-0 d-flex justify-start">
                            <v-btn
                                @click="addOtherChargesItemRow(statePrefix)"
                                color="success"
                                small
                            >{{ $t('message.addOtherCharges') }}
                            </v-btn>
                        </div>
                        <div class="dense-inputs col-8 col-lg-8 col-md-9 pa-0">
                            <v-row justify="end">
                                <v-col cols="1" lg="2" md="2" xs="3" class="pl-1 pr-3 pb-0">
                                    <div class="text-right font-weight-black">{{ $t('message.totalPieces') }}</div>
                                    <div class="text-right font-weight-black">{{ itemsTotalPieces }}</div>
                                </v-col>
                                <v-col cols="2" lg="2" md="2" xs="3" class="px-1 pb-0">
                                    <div class="text-right font-weight-black">{{ $t('message.totalVolAndQty') }}</div>
                                    <div class="text-right font-weight-black d-flex flex-column">
                                            <span v-for="(total,index) in itemsTotalQty" :class="itemsTotalQty.length > 1 && index == 0  ? 'pt-1' : ''">
                                                {{ formatThisNumber(total.Total.qty, uofmPrecision(total.Total.uofm)) + ' '+ total.Total.uofm }}
                                            </span>
                                    </div>
                                </v-col>
                                <v-col cols="1" lg="2" md="2" xs="3" class="px-1 pb-0">
                                    <div class="text-right font-weight-black">{{ $t('message.currency') }}</div>
                                    <v-container class="ma-0 pa-0">
                                        <v-row no-gutters>
                                            <v-col cols="9" offset="3">
                                                <v-select
                                                    :items="allCurrencies"
                                                    class="custom py-0 mt-0 bg-lightgrey"
                                                    hide-details
                                                    item-text="Currency.code"
                                                    item-value="Currency.id"
                                                    reverse
                                                    v-model="LocalSalesInvoice__currency_id"
                                                />
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-col>
                                <v-col cols="1" lg="2" md="2" xs="3" class="px-1 pb-0">
                                    <div class="text-right font-weight-black">
                                        <span v-if="LocalSalesInvoice__currency_id == 2">{{ getCurrencyCode(Office__currency_id) + ':' + getCurrencyCode(2) + ' ' + $t('message.exRate') }}</span>
                                        <span v-else>{{ getCurrencyCode(LocalSalesInvoice__currency_id) + ':' + getCurrencyCode(2) + ' ' + $t('message.exRate') }}</span>
                                    </div>
                                    <v-container class="ma-0 pa-0">
                                        <v-text-field
                                            :rules="[...validationRules.amount]"
                                            :value="LocalSalesInvoice__gstrmrate"
                                            class="custom py-1 pb-2 mt-0 bg-lightgrey"
                                            dense
                                            hide-details
                                            @change="LocalSalesInvoice__gstrmrate = $event"
                                        />
                                    </v-container>
                                </v-col>
                                <v-col cols="1" lg="2" md="2" xs="3" class="pl-1 pr-3 pb-0">
                                    <div class="text-right font-weight-black">{{ $t('message.totalAmount') + '(' + getCurrencyCode(2) +')' }}</div>
                                    <div class="text-right font-weight-black">
                                        <span v-if="LocalSalesInvoice__currency_id == 2">{{ formatThisNumber((itemsTotalAmount), currencyFormatPrecision) }}</span>
                                        <span v-else>{{ formatThisNumber((itemsTotalAmount / LocalSalesInvoice__gstrmrate), currencyFormatPrecision) }}</span>
                                    </div>
                                </v-col>
                                <v-col cols="1" lg="2" md="2" xs="3" class="pl-1 pr-3 pb-0">
                                    <div class="text-right font-weight-black">
                                        <span v-if="LocalSalesInvoice__currency_id == 2">{{ $t('message.totalAmount') + '(' + getCurrencyCode(Office__currency_id) +')' }}</span>
                                        <span v-else>{{ $t('message.totalAmount') + '(' + getCurrencyCode(LocalSalesInvoice__currency_id) +')' }}</span>
                                    </div>
                                    <div class="text-right font-weight-black">
                                        <span v-if="LocalSalesInvoice__currency_id == 2">{{ formatThisNumber(itemsTotalAmount * LocalSalesInvoice__gstrmrate, currencyFormatPrecision) }}</span>
                                        <span v-else>{{ formatThisNumber(itemsTotalAmount, currencyFormatPrecision) }}</span>
                                    </div>
                                </v-col>
                            </v-row>
                        </div>
                    </div>
                </template>
            </v-data-table>
        </v-form>
        <LocalSalesItemUpdate
            :currency-id="LocalSalesInvoice__currency_id"
            :dialog.sync="editItemDialog"
            :item-index="selectedItemIndex"
            :update-mode="updateMode"
            :ms-id="LocalSalesInvoice__ms_id"
            @dialog-closed="editItemDialogClosed"
            @update-done="itemUpdated"
        />
        <LocalSalesItemPriceUpdate
            :currency-id="LocalSalesInvoice__currency_id"
            :dialog.sync="editPricesDialog"
            :selected-items="selectedItems"
            :update-mode="updateMode"
            @dialog-closed="editPricesDialogClosed"
            @update-done="itemPricesUpdated"
        />
    </div>
</template>

<script>

import {mapFieldsPrefixed, mapMultiRowFieldsPrefixed} from "Helpers/vuex-map-fields-prefixed";
import {mapFields} from "vuex-map-fields";
import {mapActions, mapGetters, mapMutations} from "vuex";
import {generateDimension, formatDate, generateSpec, numberFormat, log} from "Helpers/helpers";

const LocalSalesItemUpdate = () => import('Components/Appic/LocalSalesItemUpdate.vue');
const LocalSalesItemPriceUpdate = () => import('Components/Appic/LocalSalesItemPriceUpdate.vue');

export default {
    name: 'LocalSalesInvoiceItemListV3',
    components: {LocalSalesItemUpdate, LocalSalesItemPriceUpdate},
    props: ['updateMode'],
    data () {
        return {
            allItemsSelected: false,
            allSelectedItems: [],
            editItemDialog: false,
            editPricesDialog: false,
            filteredThickness: [],
            selectedItemIndex: null,
            // selectedItemIndexes: [],
        }
    },
    computed: {
        statePrefix() {
            if (this.updateMode) {
                return 'update'
            } else {
                return 'current'
            }
        },
        ...mapFieldsPrefixed('localsalesinvoice', {
            LocalSalesInvoice__currency_id: 'LocalSalesInvoice.currency_id',
            LocalSalesInvoice__gstpercent: 'LocalSalesInvoice.gstpercent',
            LocalSalesInvoice__gstrmrate: 'LocalSalesInvoice.gstrmrate',
            LocalSalesInvoice__id: 'LocalSalesInvoice.id',
            LocalSalesInvoice__is_posted: 'LocalSalesInvoice.is_posted',
            LocalSalesInvoice__ms_id: 'LocalSalesInvoice.ms_id',
            LocalSalesInvoice__office_id: 'LocalSalesInvoice.office_id',
            LocalSalesInvoice__salestatus_id: 'LocalSalesInvoice.salestatus_id',
        },'statePrefix'),
        ...mapFields('localsalesinvoice',{
            newLocalSalesInvoiceSaved: 'newLocalSalesInvoiceSaved',
            newLocalSalesInvoiceUnsaved: 'newLocalSalesInvoiceUnsaved',
            updatedLocalSalesInvoiceUnsaved: 'updatedLocalSalesInvoiceUnsaved',
            updatedLocalSalesInvoiceSaved: 'updatedLocalSalesInvoiceSaved'
        }),
        ...mapMultiRowFieldsPrefixed('localsalesitem',{
            LocalSalesInvoiceItems: 'LocalSalesInvoiceItems',
        },'statePrefix'),
        ...mapFieldsPrefixed('localsalesitem',{
            gstpercent: 'gstpercent',
            itemsCurrencyRate: 'itemsCurrencyRate',
            itemsTotalAmount: 'itemsTotalAmount',
            itemsTotalPieces: 'itemsTotalPieces',
            itemsTotalQty: 'itemsTotalQty'
        },'statePrefix'),
        ...mapGetters('certification',{
            allCertifications: 'allCertifications'
        }),
        ...mapGetters('currency',{
            allCurrencies: 'allCurrencies'
        }),
        ...mapGetters('grade',{
            allGrades: 'allGrades',
            findGradeById: 'findGradeById'
        }),
        ...mapGetters('mc', {
            allMoistureContent: 'allMoistureContent'
        }),
        ...mapGetters('productgroup',{
            allProductGroups: 'allProductGroups'
        }),
        ...mapGetters('size',{
            allSizes: 'allSizes',
            findSizeById: 'findSizeById'
        }),
        ...mapGetters('species', {
            allSpecies: 'allSpecies',
            findSpeciesById: 'findSpeciesById'
        }),
        ...mapGetters('spec',{
            allSpecs: 'allSpecs',
            allCleanedSpecs: 'allCleanedSpecs',
            findSpecById: 'findSpecById'
        }),
        ...mapGetters('thickness', {
            allThickness: 'allThickness'
        }),
        ...mapGetters([
            'offices',
            'uofmDimensions',
            'uofmQty',
            'validationRules'
        ]),
        currencyFormatPrecision () {
            return this.allCurrencies.find((currency) => currency.Currency.id === this.LocalSalesInvoice__currency_id)?.Currency?.formatPrecision
        },
        headers() {
            return [
                {
                    id: 1,
                    text: this.$t('message.lineNo'),
                    value: 'lineNumber'
                },
                {
                    id: 2,
                    text: this.$t('message.bundle'),
                    value: 'Stock.bundleno'
                },
                {
                    id: 3,
                    text: this.$t('message.product'),
                    value: 'Stock.productgroup_id'
                },
                {
                    id: 4,
                    text: this.$t('message.size'),
                    value: 'Stock.size_id'
                },
                {
                    id: 5,
                    text: '',
                    value: 'data-table-select'
                },
                {
                    id: 6,
                    text: this.$t('message.costPrice'),
                    class: 'force-text-right',
                    value: 'Stock.costprice'
                },
                {
                    id: 7,
                    text: this.$t('message.salesPrice'),
                    class: 'force-text-right',
                    value: 'Stock.sellingprice'
                },
                {
                    id: 8,
                    text: this.$t('message.amount'),
                    class: 'force-text-right',
                    value: 'Stock.amount'
                },
                {
                    id: 9,
                    text: this.$t('message.actions'),
                    value: 'Stock.id'
                }
            ]
        },
        selectedItems() {
            let indexArray = []
            this.allSelectedItems.forEach(item => {
                indexArray.push(item.index)
            })
            return indexArray
        },
        showSelect() {
            return this.LocalSalesInvoice__is_posted != 1
        },
        someItemsSelected() {
            return this.allSelectedItems.length > 0 && this.allSelectedItems.length < this.LocalSalesInvoiceItems.length
        },
    },
    methods: {
        ...mapActions('certification', {
            getAllCertifications: 'getAllCertifications'
        }),
        ...mapActions('currency', {
            getAllCurrencies: 'getAllCurrencies'
        }),
        ...mapActions('localsalesitem', {
            addOtherChargesItemRow: 'addOtherChargesItemRow',
            deleteLocalSalesInvoiceItem: 'deleteLocalSalesInvoiceItem',
            updateTotals: 'updateTotals'
        }),
        ...mapActions('grade', {
            getAllGrades: 'getAllGrades'
        }),
        ...mapActions('mc', {
            getAllMoistureContent: 'getAllMoistureContent'
        }),
        ...mapActions('productgroup', {
            getAllProductGroups: 'getAllProductGroups'
        }),
        ...mapActions('size', {
            getAllSizes: 'getAllSizes'
        }),
        ...mapActions('spec', {
            getAllSpecs: 'getAllSpecs',
            getAllCleanedSpecs: 'getAllCleanedSpecs'
        }),
        ...mapActions('species', {
            getAllSpecies: 'getAllSpecies'
        }),
        ...mapActions('thickness', {
            getAllThickness: 'getAllThickness'
        }),
        ...mapMutations('localsalesitem', {
            deleteLocalSalesInvoiceItemRow: 'deleteLocalSalesInvoiceItemRow',
        }),
        async deleteItem (itemIndex, itemId) {
            if (await this.$root.$confirm(this.$t('message.delete'), this.$t('message.confirmations.continueDeleteItemAction'), {color: 'red'})) {
                let promise = new Promise((resolve, reject) => {
                    let payload = {
                        index: itemIndex,
                        prefix: this.statePrefix
                    }
                    this.deleteLocalSalesInvoiceItemRow(payload)
                    resolve('ok')
                });
                promise.then(() => {
                    if(itemId != null){
                        this.deleteLocalSalesInvoiceItem({
                            localSalesInvoiceId: this.LocalSalesInvoice__id,
                            itemId: itemId}
                        )
                            .then((response)=>{
                                this.updateTotals( this.statePrefix )
                            })
                    } else {
                        this.updateTotals( this.statePrefix )
                    }
                })
            }
        },
        editItemDialogClosed () {
            this.editItemDialog = false;
        },
        editPricesDialogClosed () {
            this.editPricesDialog = false;
        },
        findCurrencyPrecision (val) {
            let currency = this.allCurrencies.find(c => c.Currency.id == val)
            return currency?.Currency?.formatPrecision
        },
        filterThickness(ms) {
            let various = this.allThickness.find(t => {
                return t.Thickness.ms == ms && t.Thickness.title == 'various'
            })
            let thicknesses = this.allThickness.filter(t => {
                return t.Thickness.ms == ms && t.Thickness.title != 'various'
            })
            thicknesses.sort((a,b) => {
                let strA = a + ''
                let strB = b + ''
                return strA.localeCompare(strB, undefined, { numeric: true })
            })
            if(various){
                this.filteredThickness = [various,...thicknesses]
            } else {
                this.filteredThickness = thicknesses
            }
        },
        formatDate,
        formatThisNumber (value,format) {
            return numberFormat(value,format)
        },
        getThickness (thicknessId) {
            let thickness = ''
            const obj = this.filteredThickness.find(t => t.Thickness.id == thicknessId)
            if(obj){
                thickness = obj.Thickness?.title
            }
            return thickness
        },
        getCurrencyCode (currency_id) {
            let code = this.allCurrencies.find((currency) => currency.Currency.id == currency_id)?.Currency?.code
            return code !== undefined ? code : ''
        },
        itemPricesUpdated (value) {
            this.editPricesDialog = false;
            this.$emit('reload-item-list', value)
        },
        itemUpdated (value) {
            this.editItemDialog = false;
            this.$emit('reload-item-list', value)
        },
        openEditItemDialog(itemIndex) {
            this.selectedItemIndex = itemIndex
            this.editItemDialog = true
        },
        openEditPricesDialog() {
            if(this.allSelectedItems.length > 0) {
                this.editPricesDialog = true
            } else {
                this.$toast.error(this.$t('message.errors.noItemsSelected'),
                    {
                        classes: ['icon-float-left'],
                        icon: 'error_outline'
                    }
                )
            }
        },
        toggleAllSelect () {
            this.allItemsSelected = !this.allItemsSelected
            if(this.allItemsSelected){
                this.allSelectedItems = this.LocalSalesInvoiceItems
            } else {
                this.allSelectedItems = []
            }
        },
        uofmPrecision (uofm) {
            if(uofm) {
                if(isNaN(uofm)) {
                    return this.uofmQty.find((u) => u.Measurement.title == uofm.trim())?.Measurement?.formatPrecision
                } else {
                    return this.uofmQty.find((u) => u.Measurement.id == uofm)?.Measurement?.formatPrecision
                }
            } else {
                return '0,0.00'
            }
        },
        updateLocalSalesInvoiceItemRowHandle (idx,field,value) {
            const payload = {
                index: idx,
                field: field,
                prefix: this.statePrefix,
                value: value,
            }
            const promise = new Promise((resolve, reject) => {
                try {
                    this.$store.commit('localsalesitem/updateLocalSalesInvoiceItems', payload)
                    resolve('ok')
                } catch (error) {
                    reject(error)
                }
            });
            promise
                .then(()=>{
                    this.updateTotals(this.statePrefix)
                    log('Item updated...')
                })
        }
    },
    created () {
        if(this.allCurrencies.length == 0) this.getAllCurrencies()
        if(this.allSpecies.length == 0) this.getAllSpecies()
        if(this.allGrades.length == 0) this.getAllGrades()
        if(this.allCertifications.length == 0) this.getAllCertifications()
        if(this.allProductGroups.length == 0) this.getAllProductGroups()
        if(this.allCleanedSpecs.length == 0) this.getAllCleanedSpecs()
        if(this.allSizes.length == 0) this.getAllSizes()
        if(this.allThickness.length == 0) this.getAllThickness()
        if(this.allMoistureContent.length == 0) this.getAllMoistureContent()

        this.itemsCurrencyRate = this.LocalSalesInvoice__gstrmrate
        this.gstpercent = this.LocalSalesInvoice__gstpercent
    },
    mounted () {
        this.$store.watch(
            function (state) {
                return state.appic.localsalesitem.update.LocalSalesInvoiceItems
            },
            () => {
                this.updatedLocalSalesInvoiceUnsaved = true
            },
            {deep: true}
        )
        this.$store.watch(
            function (state) {
                return state.appic.localsalesitem.current.LocalSalesInvoiceItems
            },
            () => {
                this.newLocalSalesInvoiceUnsaved = true
            },
            {deep: true}
        )
        this.itemsLoaded = true
        this.$nextTick(function () {
            this.filteredUnits = this.uofmQty.filter(u => {
                return u.Measurement.system == this.LocalSalesInvoice__ms_id || u.Measurement.system == null
            })
            this.filterThickness(this.LocalSalesInvoice__ms_id)
        })
    }
}

</script>

<style>
.bg-lightgrey {
    background-color: #F5F5F5;
}
.v-text-field__slot input {
    text-align: right;
}
.custom.v-text-field {
    font-size: 0.75rem !important;
}
.custom.v-text-field>.v-input__control>.v-input__slot:before {
    border-style: none;
}
.custom.v-text-field>.v-input__control>.v-input__slot:after {
    border-style: none;
}
.v-data-table td {
    padding: 0 5px;
}
.font-xs {
    font-size: 0.65rem;
}
.custom .v-icon {
    font-size: 18px !important;
}
.truncate {
    width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.v-data-table tbody td {
    vertical-align: top !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
    height: 40px !important;
    font-size: 0.75rem !important;
}
.row-reverse { flex-direction: row-reverse; }
.force-text-left {
    text-align: left !important;
}
.width-18-pct {
    width: 18%;
}
.font-16px {
    font-size: 16px !important;
    line-height: 20px;
}
.dense-inputs .v-input {
    font-size: 16px !important;
}
.input-box {
    background-color: #EEEEEE;
    padding-left: 5px !important;
    padding-top: 3px !important;
    padding-bottom: 3px !important;
    min-height: 26px !important;
}
.width-30-pct {
    width: 30% !important;
}
.width-35-pct {
    width: 35% !important;
}
.width-15-pct {
    width: 15% !important;
}
</style>